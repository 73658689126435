<template>
  <div class="vz-input vz-scroll">
    <label
      v-if="label"
      class="vz-input-label"
      :for="id"
      :class="labelClass"
      v-text="label"
    />
    <div class="vz-input-wrapper">
      <textarea
        :id="id"
        ref="input"
        class="vz-input-control vz-scroll"
        autocomplete="false"
        :rows="rows"
        :name="inputName"
        :type="controlType"
        :value="modelValue"
        :class="controlClass"
        :disabled="disabled"
        :maxLength="maxLength"
        :placeholder="placeholder"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />
      <div v-if="iconName" class="vz-input-icon">
        <vz-icon
          :name="iconName"
          :size="16"
          @click="onIconClick"
        />
      </div>
    </div>
    <vz-invalid-message v-if="isShowError">
      {{ errorText }}
    </vz-invalid-message>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import {KeyValueObject, VzClass} from '~/types/types'
import { useProps } from '~/common/ui-kit-common'
import {useIconProps} from "~/common/ui-kit-icon"

type InputType = 'text' | 'password'

const { isShowError, errorText, doValidate } = useValidateble()

const emit = defineEmits([
    'blur',
    'focus',
    'update:modelValue'
])

const props = defineProps({
  ...useProps,
  ...useIconProps,
  modelValue: {
    type: String,
    default: '',
  },
  autosize: {
    type: Boolean,
    default: true,
  },
  rows: {
    type: [Number, String],
    default: null
  },
  inputClass: {
    type: [Object, String] as PropType<VzClass>,
    default: ''
  }
})

const input = ref<HTMLInputElement>()
let isFocus = false

const instance = getCurrentInstance()

const id = computed(() => {
  return `vz-input-${instance?.uid}`
})

const labelClass = computed((): KeyValueObject => {
  return {
    required: props.required,
    'public-style': props.labelPublicStyle
  }
})

const controlType = computed((): InputType => {
  return props.type === 'password' ? 'password' : 'text'
})

const inputName = computed((): string => {
  const date = new Date()
  return isFocus ? date.getTime().toString() + date.getMilliseconds().toString() : props.name
})

const controlClass = computed(() => {
  const classes: VzClass = {
    big: props.big,
    small: props.small,
    'has-error': isShowError.value,
    iconical: props.iconName
  }
  if (typeof props.inputClass === 'string' && props.inputClass !== '') {
    classes[props.inputClass] = true
  }
  return classes
})

const autosizeHeight = (): void => {
  const tx = input.value
  if (!props.autosize || !tx) {
    return;
  }

  const { height } = window.getComputedStyle(tx, null)
  // Уменьшаем ширину до минимума, а потом авторесайз
  if (tx && tx.scrollHeight > Number.parseInt(height)) {
    tx.setAttribute('style', 'height:38px;');
    tx.setAttribute('style', 'height:' + (tx.scrollHeight) + 'px;');
  }
}

async function checkError(silent: boolean = false) {
  return await doValidate(props.modelValue, props.rules, silent)
}

const onBlur = (event: Event): void => {
  isFocus = false
  nextTick(() => {
    autosizeHeight()
    checkError()
  })
  emit('blur', event)
}

const onFocus = (event: Event): void => {
  isFocus = true
  emit('focus', event)
}

function onInput(e: any): void {
  emit('update:modelValue', e.target.value)
  nextTick(async () => {
    await checkError()
    autosizeHeight();
  })
}

const onIconClick = (): void => {
  input.value?.focus()
}

defineExpose({
  checkError,
  input,
  isShowError
})
</script>
